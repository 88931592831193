import { useEffect } from 'react'

import useAuth from '@/hooks/useAuth'

import PageLoader from '@/components/ui/PageLoader'
import { mixpanel } from '@/utils/analytics'
import requireNoAuth from '@/utils/auth/requireNoAuth'

function Landing() {
  const auth = useAuth()

  useEffect(() => {
    mixpanel.track('Page View: LoginForm')
    auth?.logIn()
  }, [])

  return <PageLoader />
}
const LandingPage = requireNoAuth(Landing)

export default LandingPage
